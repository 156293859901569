import React from 'react';  
import './Popup.css';  

class Popup extends React.Component {  
    render() {  
        return (  
            <div className='popup'>  
                <div className='popup\_inner'>  
                    <h1>{this.props.text}</h1>  
                    <button className="ui submit small green button left" onClick={this.props.closePopup}>Done</button>  
                </div>  
            </div>
        );
    }  
}  

export default Popup;