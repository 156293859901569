export const siteName = "The Community Health Center Of Lubbock - Caring For Lubbock"
export const siteCode = "CHCL"
export const continueUrl = "https://demo.SmartViewMD.com"
export const siteDomains = [ "doctors.test", "chcl.tachc.org" ] 
export const siteLogo = `/img/${siteCode}_354x123.jpg`
export const siteLogoSmall = `/img/${siteCode}_102x93.jpg`
export const doctors = [ 
    { value: "",                                label: "* DEMO: Select your primary"              },
    { value: "Demo: Dr. Allan Camacho, MD",     label: "DEMO: Dr. Allan Camacho, MD"              },
    { value: "Demo: Dr. Lubbock Texas, MD",     label: "DEMO: Dr. Lubbock Texas, MD"              },
    { value: "Demo: Dr. Demo Training, MD",     label: "DEMO: Dr. Demo Training, MD"              },
    { value: "Demo: Dr. Ash Walters, DO",       label: "DEMO: Dr. Ash Walters, DO"                },
    { value: "Demo: Dr. Dickens Smith, MD",     label: "DEMO: Dr. Dickens Smith, MD"              },
    { value: "Demo: Dr. John Johnathon, MD",    label: "DEMO: Dr. Johnathon Morton, MD"           },
    { value: "Demo: Dr. Leo Jusme, SVMD",       label: "DEMO: Dr. Leo Jusme, SVMD DemoTest"       },
    { value: "Demo: Dr. Samantha Carter, SVMD", label: "DEMO: Dr. Samantha Carter, SVMD DemoTest" },
    { value: "Demo: Dr. D. Northecide, SVMD",   label: "DEMO: Dr. D. Northecide, SVMD DemoTest"   },
]